import './NotificationItem.scss';
import React from 'react';
import {Notification} from '../../types/NotificationTypes';

export interface NotificationProps {
    notification: Notification;
    onClose: () => void;
}

const NotificationItem: React.FC<NotificationProps> = ({ notification, onClose }) => {
    return (
        <div className={`notification ${notification.type}`}>
            {notification.message}
            <button onClick={onClose}>×</button>
        </div>
    );
};

export default NotificationItem;
