import {Notification, NotificationType} from "../types/NotificationTypes";
import {addNotification} from "../store/notificationsSlice";
import {Dispatch} from 'redux';
import {AxiosError} from 'axios';

export function createNotification(type: NotificationType, message: string, dispatch: Dispatch) {
    let notification: Notification = {
        id: "",
        type: type,
        message: message
    };
    console.log(`Notification: "${type}": "${message}"`);
    dispatch(addNotification(notification));
}

export function wrapError(error: unknown): string {
    let errorMessage: string;

    if (error instanceof Error) {
        errorMessage = error.message;

        if (error instanceof AxiosError) {
            if (error.response) {
                let status = error.response.status;
                let data = typeof error.response.data === 'string'
                    ? error.response.data
                    : error.response.data.message;

                errorMessage += ` (HTTP ${status}: ${data})`;
            } else if (error.request) {
                errorMessage += " (The request was made but no response was received)";
            }
        }
    } else if (typeof error === 'string') {
        errorMessage = error;
    } else if (error && typeof error === 'object') {
        try {
            errorMessage = JSON.stringify(error);
        } catch (jsonError) {
            errorMessage = "An unknown error occurred and could not be serialized";
        }
    } else {
        errorMessage = "An unknown error occurred";
    }

    return errorMessage;
}
