import React from 'react';
import {Navigate} from 'react-router-dom';
import {isAuthenticated} from "../../services/securityService";

const PublicRoute: React.FC<{ element: React.ReactElement }> = ({element}) => {
    return isAuthenticated()
        ? <Navigate replace to="/home"/>
        : element;
};

export default PublicRoute;

