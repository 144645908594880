import {AuthRequest, AuthResponse, UserRegistrationRequest} from "../types/SecurityTypes";
import http from "./httpService";

export function sendLogin(credentials: AuthRequest): Promise<AuthResponse> {
    return http.post(
        '/api/v1/auth/login',
        credentials
    )
        .then(response => {
            const result: AuthResponse = response.data;
            return result;
        })
        .catch(error => {
            if (error.response && error.response.status === 500) {
                console.error("Server error!", error.response);
            } else {
                console.error("An error occurred", error);
            }
            throw error;
        });
}

export function sendLogout(): Promise<void> {
    return http.post(
        '/api/v1/auth/logout'
    ).then(response => {
        console.log(response);
    })
}


export function sendRegister(registrationRequest: UserRegistrationRequest): Promise<AuthResponse> {
    return http.post(
        '/api/v1/auth/register',
        registrationRequest
    )
        .then(response => {
            const result: AuthResponse = response.data;
            return result;
        })
        .catch(error => {
            if (error.response && error.response.status === 500) {
                console.error("Server error!", error.response);
            } else {
                console.error("An error occurred", error);
            }
            throw error;
        });
}

export function sendDefaultApi(): Promise<void> {
    return http.get(
        '/api/v1/default/hello'
    ).then(response => {
        console.log(response);
    })
}

export function sendAdminApi(): Promise<void> {
    return http.get(
        '/api/v1/default/admin'
    ).then(response => {
        console.log(response);
    })
}