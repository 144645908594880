import './NotificationContainer.scss';
import NotificationItem from './NotificationItem';
import React from 'react';
import {RootState} from '../../store/store';
import {useSelector, useDispatch} from 'react-redux';
import {removeNotification} from '../../store/notificationsSlice';

const NotificationContainer: React.FC = () => {
    const dispatch = useDispatch();
    const notifications = useSelector((state: RootState) => state.notifications);

    return (
        <div className="notification-container">
            {notifications.map(notification => (
                <NotificationItem
                    key={notification.id}
                    notification={notification}
                    onClose={() => dispatch(removeNotification(notification.id))}
                />
            ))}
        </div>
    );
};

export default NotificationContainer;
