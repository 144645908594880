import React from "react";
import './HomePage.scss';
import {useNavigate} from "react-router-dom";
import {apiAdmin, apiDefault, logout} from "../../services/securityService";
import {useDispatch} from 'react-redux';
import {Dispatch} from 'redux';
import {createNotification} from "../../services/notificationService";

function HomePage() {
    const navigate = useNavigate();
    const dispatch: Dispatch = useDispatch();

    const handleLogout = async () => {
        await logout(navigate, dispatch);
    }

    const handleMakeNotification = async () => {
        createNotification("success", "Hello World!", dispatch);
    }

    const handleDefaultApi = async () => {
        await apiDefault(dispatch);
    }

    const handleAdminApi = async () => {
        await apiAdmin(dispatch);
    }

    return (
        <div className="home">
            <h2 className="home__title">Hello, World!</h2>
            <button className="home__logout" onClick={handleLogout}>Logout</button>
            <button className="home__notification" onClick={handleMakeNotification}>Make notification</button>
            <button className="home__api" onClick={handleDefaultApi}>Default API</button>
            <button className="home__api" onClick={handleAdminApi}>Admin API</button>
        </div>
    );
}

export default HomePage;