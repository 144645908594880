import React, {useState} from "react";
import './LoginPage.scss';
import {useNavigate} from "react-router-dom";
import {login} from "../../services/securityService";
import {Dispatch} from "redux";
import {useDispatch} from "react-redux";

function LoginPage() {
    const navigate = useNavigate();
    const dispatch: Dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        await login(username, password, navigate, dispatch);
    }

    return (
        <div className="login">
            <form className="login-form" action="#">
                <h2 className="login-form__item login-form__title">Login</h2>

                <input className="login-form__item login-form__field"
                       name="username"
                       type="text"
                       value={username}
                       onChange={(e) => setUsername(e.target.value)}
                       placeholder="Enter username"/>

                <input className="login-form__item login-form__field"
                       name="password"
                       type="password"
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}
                       placeholder="Enter password"/>

                <button className="login-form__item login-form__button" onClick={handleLogin}>login</button>

                <a className="login-form__item login-form__label" href={"/register"}>Create a new account.</a>
            </form>
        </div>
    );
}

export default LoginPage;