import React from 'react';
import {Navigate} from 'react-router-dom';
import {isAuthenticated} from "../../services/securityService";

const ProtectedRoute: React.FC<{ element: React.ReactElement }> = ({element}) => {
    return isAuthenticated()
        ? element
        : <Navigate replace to="/login"/>;
};

export default ProtectedRoute;
