import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {uuidGenerate} from "../services/uuidUtils"
import {Notification} from '../types/NotificationTypes';

const initialState: Notification[] = [];

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNotification: {
            reducer: (state, action: PayloadAction<Notification>) => {
                state.push(action.payload);
            },
            prepare: (notification: Omit<Notification, 'id'>) => ({
                payload: {
                    ...notification,
                    id: uuidGenerate()
                }
            })
        },

        removeNotification: (state, action: PayloadAction<string>) => {
            return state.filter(notification => notification.id !== action.payload);
        }
    }
});

export const {
    addNotification,
    removeNotification
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
