import {sendAdminApi, sendDefaultApi, sendLogin, sendLogout, sendRegister} from "./apiService"
import {AuthRequest, UserRegistrationRequest} from "../types/SecurityTypes";
import {Dispatch} from "redux";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {createNotification, wrapError} from "./notificationService";


export function isAuthenticated() {
    return !!localStorage.getItem('jwt-access-token');
}

export async function login(username: string, password: string, navigate: NavigateFunction, dispatch: Dispatch): Promise<void> {
    try {
        const authRequest: AuthRequest = {
            username: username,
            password: password
        };

        const authResponse = await sendLogin(authRequest);
        localStorage.setItem('jwt-access-token', authResponse.accessToken);
        navigate('/home');
    } catch (error) {
        createNotification("error", wrapError(error), dispatch);
    }
}

export async function logout(navigate: NavigateFunction, dispatch: Dispatch): Promise<void> {
    try {
        await sendLogout();
        localStorage.removeItem('jwt-access-token');
        navigate('/login');
    } catch (error) {
        createNotification("error", wrapError(error), dispatch);
    }
}

export async function register(username: string, password: string, navigate: NavigateFunction, dispatch: Dispatch): Promise<void> {
    try {
        const authRequest: UserRegistrationRequest = {
            username: username,
            password: password
        };

        const authResponse = await sendRegister(authRequest);
        localStorage.setItem('jwt-access-token', authResponse.accessToken);
        navigate('/home');
    } catch (error) {
        createNotification("error", wrapError(error), dispatch);
    }
}

export async function apiDefault(dispatch: Dispatch): Promise<void> {
    try {
        await sendDefaultApi();
        createNotification("info", "Default API works!", dispatch);
    } catch (error) {
        createNotification("error", wrapError(error), dispatch);
    }
}

export async function apiAdmin(dispatch: Dispatch): Promise<void> {
    try {
        await sendAdminApi();
        createNotification("info", "Admin API works!", dispatch);
    } catch (error) {
        createNotification("error", wrapError(error), dispatch);
    }
}