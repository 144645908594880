import axios from 'axios';
import {getEnvVariable} from "./environmentService";

const API_HOST = getEnvVariable('REACT_APP_BACKEND_HOST');
const API_SECURED = getEnvVariable('REACT_APP_BACKEND_SECURED') === 'true';
const API_HTTP_PORT = getEnvVariable('REACT_APP_BACKEND_HTTP_PORT');
const API_HTTP_URL = `${API_SECURED ? 'https://' : 'http://'}${API_HOST}:${API_HTTP_PORT}`;

const http = axios.create({
    baseURL: API_HTTP_URL,
    headers: {
        "Content-Type": "application/json"
    }
});

http.interceptors.request.use(config => {
    const token = localStorage.getItem('jwt-access-token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

http.interceptors.response.use(response => response, error => {
    const originalRequest = error.config;
    if (originalRequest.url.includes("/api/v1/auth/")) {
        return Promise.reject(error);
    }
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        return http.post('/api/v1/auth/refresh')
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem('jwt-access-token', res.data.accessToken);
                    http.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`;
                    originalRequest.headers['Authorization'] = `Bearer ${res.data.accessToken}`;

                    return http(originalRequest);
                }
                return Promise.reject(res);
            })
            .catch(refreshError => {
                console.error("Unable to refresh token", refreshError);
                localStorage.removeItem('jwt-access-token');
                return Promise.reject(refreshError);
            });
    }
    return Promise.reject(error);
});


export function init(): void {
    console.log(`API_HOST = ${API_HOST}`);
    console.log(`API_SECURED = ${API_SECURED}`);
    console.log(`API_HTTP_PORT = ${API_HTTP_PORT}`);
    console.log(`API_HTTP_URL = ${API_HTTP_URL}`);
}

export default http;
