import './RegisterPage.scss';
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {register} from "../../services/securityService";
import {Dispatch} from "redux";
import {useDispatch} from "react-redux";


function RegisterPage() {
    const navigate = useNavigate();
    const dispatch: Dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleRegister = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        await register(username, password, navigate, dispatch);
    }

    return (
        <div className="register">
            <form className="register-form" action="#">
                <h2 className="register-form__item register-form__title">Registration</h2>

                <input className="register-form__item register-form__field"
                       name="username"
                       type="text"
                       value={username}
                       onChange={(e) => setUsername(e.target.value)}
                       placeholder="Enter username"/>

                <input className="register-form__item register-form__field"
                       name="password"
                       type="password"
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}
                       placeholder="Enter password"/>

                <button className="register-form__item register-form__button" onClick={handleRegister}>Register</button>

                <a className="register-form__item register-form__label" href={"/login"}>I already have account.</a>
            </form>
        </div>
    );
}

export default RegisterPage;